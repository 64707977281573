import './App.css';
import Routing from './navigation/Routing';

function App() {

  return (
    <>
      <Routing/>
    </>
  )
}

export default App
