import viewOne from "../images/mogra/view1.jpg";
import viewTwo from "../images/mogra/view2.jpg";
import viewThree from "../images/mogra/view3.jpg";
import viewFour from "../images/mogra/view4.jpg";
import viewFive from "../images/mogra/view5.jpg";
import viewSix from "../images/mogra/view6.jpg";
import viewSeven from "../images/mogra/view7.jpg";
import viewEight from "../images/mogra/view8.jpg";
import viewNine from "../images/mogra/view9.jpg";
import viewTen from "../images/mogra/view10.jpg";
import viewEleven from "../images/mogra/view11.jpg";
import viewTwelve from "../images/mogra/view12.jpg";
import viewThirteen from "../images/mogra/view13.jpg";
import viewFourteen from "../images/mogra/view14.jpg";
import viewFifteen from "../images/mogra/view15.jpg";
import viewSixteen from "../images/mogra/view16.jpg";
import viewSeventeen from "../images/mogra/view17.jpg";
import viewEighteen from "../images/mogra/view18.jpg";
import viewNinteen from "../images/mogra/view19.jpg";
import viewTwenty from "../images/mogra/view20.jpg";
import viewTwentyOne from "../images/mogra/view21.jpg";
import viewTwentyTwo from "../images/mogra/view22.jpg";
import viewTwentyThree from "../images/mogra/view23.jpg";
import viewTwentyFour from "../images/mogra/view24.jpg";
import viewTwentyFive from "../images/mogra/view25.jpg";
import viewTwentySix from "../images/mogra/view26.jpg";
import viewTwentySeven from "../images/mogra/view27.jpg";
import viewTwentyEight from "../images/mogra/view28.jpg";
import viewTwentyNine from "../images/mogra/view29.jpg";
import viewThirty from "../images/mogra/view28.jpg";
import viewThirtyOne from "../images/mogra/view29.jpg";
import viewThirtyTwo from "../images/mogra/view30.jpg";

const Scene = {
  pointOneView: {
    title: "Living / Dining [Mogra]",
    image: viewOne,
    pitch: -19.216959762066494,
    yaw: 139.80652381375117,
    hotSpots: {
      pointTwo: {
        type: "custom",
        pitch: -38.91787066505156,
        yaw: 125.6100599050256,
        cssClass: "moveScene",
        scene: "pointTwoView",
        label: "Go to Inside",
      },
    },
  },
  pointTwoView: {
    title: "Living / Dining [Mogra]",
    image: viewTwo,
    pitch: -26.589790900179512,
    yaw: -178.98112692274046,
    hotSpots: {
      pointThree: {
        type: "custom",
        pitch: -36.983070356987646,
        yaw: 175.8629515210789,
        cssClass: "moveScene",
        scene: "pointThreeView",
      },
      pointFour: {
        type: "custom",
        pitch: -8.541747941323495,
        yaw: 173.39055181454938,
        cssClass: "moveScene",
        scene: "pointFourView",
      },
      pointEight: {
        type: "custom",
        pitch: -18.16221890773513,
        yaw: 154.719833991992,
        cssClass: "moveScene",
        scene: "pointEightView",
      },
      pointFifteen: {
        type: "custom",
        pitch: -37.99057455451875,
        yaw: 107.01291079807568,
        cssClass: "moveScene",
        scene: "pointFifteenView",
      },
    },
  },
  pointThreeView: {
    title: "Living / Dining [Mogra]",
    image: viewThree,
    pitch: -31.028432999024336,
    yaw: 112.44932855391721,
    hotSpots: {
      pointFive: {
        type: "custom",
        pitch: -7.934291354289273,
        yaw: 170.49253482746659,
        cssClass: "moveScene",
        scene: "pointFiveView",
        text: "Exit",
      },
      pointEight: {
        type: "custom",
        pitch: -25.02831642247924,
        yaw: 137.48083991200247,
        cssClass: "moveScene",
        scene: "pointEightView",
        text: "Exit",
      },
      pointNine: {
        type: "custom",
        pitch: -1.4744220372793702,
        yaw: 126.19632203656715,
        cssClass: "moveScene",
        scene: "pointNineView",
        text: "Exit",
      },
      pointFourteen: {
        type: "custom",
        pitch: -22.440770466847464,
        yaw: 111.80565211411644,
        cssClass: "moveScene",
        scene: "pointFourteenView",
        text: "Exit",
      },
      pointSixteen: {
        type: "custom",
        pitch: -1.6266947186326774,
        yaw: 32.38617169267031,
        cssClass: "moveScene",
        scene: "pointSixteenView",
        text: "Exit",
      },
      pointFifteen: {
        type: "custom",
        pitch: -21.982699009425286,
        yaw: 60.757862921821506,
        cssClass: "moveScene",
        scene: "pointFifteenView",
        text: "Exit",
      },
    },
  },
  pointFourView: {
    title: "Living / Dining [Mogra]",
    image: viewFour,
    pitch: -20.48245052345112,
    yaw: 29.330252096065266,
    hotSpots: {
      pointThree: {
        type: "custom",
        pitch: -33.23231552751243,
        yaw: 2.6904123793733556,
        cssClass: "moveScene",
        scene: "pointThreeView",
      },
      pointFive: {
        type: "custom",
        pitch: -16.790896942179426,
        yaw: 144.9737198108538,
        cssClass: "moveScene",
        scene: "pointFiveView",
      },
      pointEight: {
        type: "custom",
        pitch: -30.135307500021582,
        yaw: 80.34363978652654,
        cssClass: "moveScene",
        scene: "pointEightView",
      },
      pointNine: {
        type: "custom",
        pitch: -4.7390321358480465,
        yaw: 106.04344193498304,
        cssClass: "moveScene",
        scene: "pointNineView",
      },
      pointSixteen: {
        type: "custom",
        pitch: -4.877533839896596,
        yaw: 19.85424953801557,
        cssClass: "moveScene",
        scene: "pointSixteenView",
      },
    },
  },
  pointFiveView: {
    title: "Kitchen [Mogra]",
    image: viewFive,
    pitch: -18.671702032626687,
    yaw:-59.19459718348499,
    hotSpots: {
      pointSeven: {
        type: "custom",
        pitch: -22.215545320247685,
        yaw: -89.3189827521844,
        cssClass: "moveScene",
        scene: "pointSevenView",
      },
      pointSix: {
        type: "custom",
        pitch: -21.051471328772607,
        yaw: -40.535668821296305,
        cssClass: "moveScene",
        scene: "pointSixView",
      },
      pointEight: {
        type: "custom",
        pitch: -34.0759835897357,
        yaw: 117.76776724219592,
        cssClass: "moveScene",
        scene: "pointEightView",
      },
      pointFour: {
        type: "custom",
        pitch: -27.076048420824883,
        yaw: 72.98228486616621,
        cssClass: "moveScene",
        scene: "pointFourView",
      },
    },
  },
  pointSixView: {
    title: "Kitchen [Mogra]",
    image: viewSix,
    pitch: -32.9540916304044,
    yaw: 95.50360028903825,
    hotSpots: {
      pointEight: {
        type: "custom",
        pitch: -26.543433600651532,
        yaw: 96.95843163055522,
        cssClass: "moveScene",
        scene: "pointEightView",
      },
      pointFour: {
        type: "custom",
        pitch: -17.7329379395277,
        yaw: 79.04045913697409,
        cssClass: "moveScene",
        scene: "pointFourView",
      },
    },
  },
  pointSevenView: {
    title: "Kitchen [Mogra]",
    image: viewSeven,
    pitch: -34.723509610851934,
    yaw: 67.31129097174063,
    hotSpots: {
      pointSix: {
        type: "custom",
        pitch: -20.635592449648314,
        yaw: 61.35806893055193,
        cssClass: "moveScene",
        scene: "pointSixView",
      },
      pointFour: {
        type: "custom",
        pitch: -25.96334541707909,
        yaw: 137.5635781395914,
        cssClass: "moveScene",
        scene: "pointFourView",
      },
      pointEight: {
        type: "custom",
        pitch: -37.57702078542065,
        yaw: 173.35777823639978,
        cssClass: "moveScene",
        scene: "pointEightView",
      },
    },
  },
  pointEightView: {
    title: "Living / Dining [Mogra]",
    image: viewEight,
    pitch: -11.783797178010756,
    yaw: 124.93367334269487,
    hotSpots: {
      pointFive: {
        type: "custom",
        pitch: -48.81515834024971,
        yaw: -59.713708827390036,
        cssClass: "moveScene",
        scene: "pointFiveView",
      },
      pointNine: {
        type: "custom",
        pitch: -13.765336029236671,
        yaw: -154.71545856054013,
        cssClass: "moveScene",
        scene: "pointNineView",
      },
      pointFourteen: {
        type: "custom",
        pitch: -28.551955519280295,
        yaw: 138.78182897565537,
        cssClass: "moveScene",
        scene: "pointFourteenView",
      },
      pointSixteen: {
        type: "custom",
        pitch: -7.346591049907813,
        yaw: 88.48869442289396,
        cssClass: "moveScene",
        scene: "pointSixteenView",
      },
      pointThree: {
        type: "custom",
        pitch: -26.03869751855363,
        yaw: 52.50578584501009,
        cssClass: "moveScene",
        scene: "pointThreeView",
      },
      pointFive: {
        type: "custom",
        pitch: -48.81515834024971,
        yaw: -59.713708827390036,
        cssClass: "moveScene",
        scene: "pointFiveView",
      },
    },
  },
  pointNineView: {
    title: "Bedroom [Mogra]",
    image: viewNine,
    pitch: -11.761147092744306,
    yaw: 92.50802773413729,
    hotSpots: {
      pointTen: {
        type: "custom",
        pitch: -23.82031253339415,
        yaw: 63.572261211357414,
        cssClass: "moveScene",
        scene: "pointTenView",
      },
      pointEleven: {
        type: "custom",
        pitch: -13.177550682410297,
        yaw: 97.61222831578593,
        cssClass: "moveScene",
        scene: "pointElevenView",
      },
      pointTwelve: {
        type: "custom",
        pitch: -8.97276226221975,
        yaw: 117.43161107227778,
        cssClass: "moveScene",
        scene: "pointTwelveView",
      },
    },
  },
  pointTenView: {
    title: "Bedroom [Mogra]",
    image: viewTen,
    pitch: -24.678637566434166,
    yaw: 80.84733979557515,
    hotSpots: {
      pointEight: {
        type: "custom",
        pitch: -20.24498310361321,
        yaw: 173.33147249025637,
        cssClass: "moveScene",
        scene: "pointEightView",
      },
      pointTwentyEight: {
        type: "custom",
        pitch: -16.6363593201525,
        yaw: 143.7750880744407,
        cssClass: "moveScene",
        scene: "pointTwentyEightView",
      },
      pointTwentyFour: {
        type: "custom",
        pitch: -11.449648317296536,
        yaw: -22.88041044430858,
        cssClass: "moveScene",
        scene: "pointTwentyFourView",
      },
      pointEleven: {
        type: "custom",
        pitch: -24.124941706998847,
        yaw: 24.87430500932826,
        cssClass: "moveScene",
        scene: "pointElevenView",
      },
      pointTwelve: {
        type: "custom",
        pitch: -18.084458395199928,
        yaw: 49.070305962994524,
        cssClass: "moveScene",
        scene: "pointTwelveView",
      },
    },
  },
  pointElevenView: {
    title: "Bedroom [Mogra]",
    image: viewEleven,
    pitch: -10.541090624427898,
    yaw: 112.94029216916039,
    hotSpots: {
      pointTwentyFour: {
        type: "custom",
        pitch: -11.599091783106932,
        yaw: -130.8160602349878,
        cssClass: "moveScene",
        scene: "pointTwentyFourView",
      },
      pointTen: {
        type: "custom",
        pitch: -33.503147750901114,
        yaw: -168.39438865547694,
        cssClass: "moveScene",
        scene: "pointTenView",
      },
      pointEight: {
        type: "custom",
        pitch: -8.128893778224368,
        yaw: 176.88311229944512,
        cssClass: "moveScene",
        scene: "pointEightView",
      },
      pointTwentyEight: {
        type: "custom",
        pitch: -8.122760568340542,
        yaw: 159.38235769422903,
        cssClass: "moveScene",
        scene: "pointTwentyEightView",
      },
      pointTwelve: {
        type: "custom",
        pitch: -19.218439902975064,
        yaw: 83.16882519639233,
        cssClass: "moveScene",
        scene: "pointTwelveView",
      },
    },
  },
  pointTwelveView: {
    title: "Bedroom [Mogra]",
    image: viewTwelve,
    pitch: -11.923256997956226,
    yaw: 5.226221879685431,
    hotSpots: {
      pointTen: {
        type: "custom",
        pitch: -25.773307148358626,
        yaw: 131.7087011529123,
        cssClass: "moveScene",
        scene: "pointTenView",
      },
      pointEight: {
        type: "custom",
        pitch: -8.864843610047457,
        yaw: 102.68240414493792,
        cssClass: "moveScene",
        scene: "pointEightView",
      },
      pointTwentyEight: {
        type: "custom",
        pitch: -9.266648171411008,
        yaw: 84.54131886718498,
        cssClass: "moveScene",
        scene: "pointTwentyEightView",
      },
      pointTwentyFour: {
        type: "custom",
        pitch: -5.997244412933413,
        yaw: 157.11427247446667,
        cssClass: "moveScene",
        scene: "pointTwentyFourView",
      },
    },
  },
  pointThirteenView: {
    title: "Bedroom [Mogra]",
    image: viewThirteen,
    pitch: -18.045704246324078,
    yaw: 129.98242531116387,
    hotSpots: {
      pointFourteen: {
        type: "custom",
        pitch: -17.1337861770463,
        yaw: 91.0143808124017,
        cssClass: "moveScene",
        scene: "pointFourteenView",
      },
    },
  },
  pointFourteenView: {
    title: "Living / Dining [Mogra]",
    image: viewFourteen,
    pitch: -14.86395768578739,
    yaw: 92.81990602227545,
    hotSpots: {
      pointFifteen: {
        type: "custom",
        pitch: -24.482224822379532,
        yaw: 46.15094480742481,
        cssClass: "moveScene",
        scene: "pointFifteenView",
      },
      pointTwentySix: {
        type: "custom",
        pitch: -14.20107446424401,
        yaw: 125.25805620734945,
        cssClass: "moveScene",
        scene: "pointTwentySixView",
      },
      pointTwentyFive: {
        type: "custom",
        pitch: -23.9099294361826,
        yaw: -175.09416064027275,
        cssClass: "moveScene",
        scene: "pointTwentyFiveView",
      },
    },
  },
  pointFifteenView: {
    title: "Living / Dining [Mogra]",
    image: viewFifteen,
    pitch: -9.467519857646197,
    yaw: 93.57203796856706,
    hotSpots: {
      pointThree: {
        type: "custom",
        pitch: -40.198994944137524,
        yaw: -128.52810187915287,
        cssClass: "moveScene",
        scene: "pointThreeView",
      },
      pointSixteen: {
        type: "custom",
        pitch: -20.151326788245136,
        yaw: 13.270369488802789,
        cssClass: "moveScene",
        scene: "pointSixteenView",
      },
      pointFourteen: {
        type: "custom",
        pitch: -27.560207516316844,
        yaw: 160.50985187363005,
        cssClass: "moveScene",
        scene: "pointFourteenView",
      },
      pointThirtyTwo: {
        type: "custom",
        pitch: -32.65907344416371,
        yaw: 82.41819409622258,
        cssClass: "moveScene",
        scene: "pointThirtyTwoView",
      },
    },
  },
  pointSixteenView: {
    title: "Bedroom [Mogra]",
    image: viewSixteen,
    pitch: -24.360855812598594,
    yaw: 143.12327131347433,
    hotSpots: {
      pointTwenty: {
        type: "custom",
        pitch: -23.415973958192936,
        yaw: 85.19063792839638,
        cssClass: "moveScene",
        scene: "pointTwentyView",
      },
      pointSeventeen: {
        type: "custom",
        pitch: -38.09512641289043,
        yaw: 173.91478313458478,
        cssClass: "moveScene",
        scene: "pointSeventeenView",
      },
      pointNinteen: {
        type: "custom",
        pitch: -15.603461099135691,
        yaw: 158.24389937273028,
        cssClass: "moveScene",
        scene: "pointNinteenView",
      },
      pointFifteen: {
        type: "custom",
        pitch: -23.572718205255633,
        yaw: -38.234849319746715,
        cssClass: "moveScene",
        scene: "pointFifteenView",
      },
      pointThirty: {
        type: "custom",
        pitch: -17.481937026497782,
        yaw: 42.28190873042233,
        cssClass: "moveScene",
        scene: "pointThirtyView",
      },
    },
  },
  pointSeventeenView: {
    title: "Bedroom [Mogra]",
    image: viewSeventeen,
    pitch: -28.998489298089456,
    yaw: 66.07677316322736,
    hotSpots: {
      pointSixteen: {
        type: "custom",
        pitch: -38.681898657105265,
        yaw: 5.4660337171306095,
        cssClass: "moveScene",
        scene: "pointSixteenView",
      },
      pointThirty: {
        type: "custom",
        pitch: -12.397255771833708,
        yaw: 14.460075378093887,
        cssClass: "moveScene",
        scene: "pointThirtyView",
      },
      pointNinteen: {
        type: "custom",
        pitch: -37.13428946426619,
        yaw: 126.70253515138059,
        cssClass: "moveScene",
        scene: "pointNinteenView",
      },
      pointTwentyOne: {
        type: "custom",
        pitch: -11.85827662183266,
        yaw: 174.0618432080359,
        cssClass: "moveScene",
        scene: "pointTwentyOneView",
      },
    },
  },
  pointEighteenView: {
    title: "Bedroom [Mogra]",
    image: viewEighteen,
    pitch: -19.264348452432834,
    yaw: 15.979879420540247,
    hotSpots: {
      pointNinteen: {
        type: "custom",
        pitch: -25.627561237471276,
        yaw: 26.0742273283227,
        cssClass: "moveScene",
        scene: "pointNinteenView",
      },
    },
  },
  pointNinteenView: {
    title: "Bedroom [Mogra]",
    image: viewNinteen,
    pitch: -15.640447439329254,
    yaw: 53.03530495649248,
    hotSpots: {
      pointSeventeen: {
        type: "custom",
        pitch: -27.67272890340666,
        yaw: 35.027718589408934,
        cssClass: "moveScene",
        scene: "pointSeventeenView",
      },
      pointThirty: {
        type: "custom",
        pitch: -7.16651992496192,
        yaw: 78.12824188160978,
        cssClass: "moveScene",
        scene: "pointThirtyView",
      },
      pointTwentyOne: {
        type: "custom",
        pitch: -9.810139721692314,
        yaw: -21.652072516915908,
        cssClass: "moveScene",
        scene: "pointTwentyOneView",
      },
      pointSixteen: {
        type: "custom",
        pitch: -23.633619281143705,
        yaw: 67.05324790017681,
        cssClass: "moveScene",
        scene: "pointSixteenView",
      },
    },
  },
  pointTwentyView: {
    title: "Bedroom [Mogra]",
    image: viewTwenty,
    pitch:-28.602525940283222,
    yaw: 41.63391159575764,
    hotSpots: {
      pointSeventeen: {
        type: "custom",
        pitch: -25.99366475795496,
        yaw: 113.09673039590031,
        cssClass: "moveScene",
        scene: "pointSeventeenView",
      },
      pointFifteen: {
        type: "custom",
        pitch: -14.785313386658029,
        yaw: -175.5641377115883,
        cssClass: "moveScene",
        scene: "pointFifteenView",
      },
      pointNinteen: {
        type: "custom",
        pitch: -13.7742719277379,
        yaw: 84.80432710520574,
        cssClass: "moveScene",
        scene: "pointNinteenView",
      },
      pointThirty: {
        type: "custom",
        pitch: -39.11670321993604,
        yaw: -130.47293935481204,
        cssClass: "moveScene",
        scene: "pointThirtyView",
      },
    },
  },
  pointTwentyOneView: {
    title: "Balcony [Mogra]",
    image: viewTwentyOne,
    pitch: -21.40758124190397,
    yaw: 91.30898004895812,
    hotSpots: {
      pointTwentyTwo: {
        type: "custom",
        pitch: -30.136560406207778,
        yaw: -56.62892019981538,
        cssClass: "moveScene",
        scene: "pointTwentyTwoView",
      },
      pointTwentyThree: {
        type: "custom",
        pitch: -26.69796859955448,
        yaw: 80.11050777918172,
        cssClass: "moveScene",
        scene: "pointTwentyThreeView",
      },
      pointEleven: {
        type: "custom",
        pitch: -5.523638638180018,
        yaw: 95.0358747982249,
        cssClass: "moveScene",
        scene: "pointElevenView",
      },
      pointSeventeen: {
        type: "custom",
        pitch: -7.247749053164793,
        yaw: 142.60668951670965,
        cssClass: "moveScene",
        scene: "pointSeventeenView",
      },
    },
  },
  pointTwentyTwoView: {
    title: "Balcony [Mogra]",
    image: viewTwentyTwo,
    pitch: -37.974606689239266,
    yaw: 65.89015609645118,
    hotSpots: {
      pointTwentyOne: {
        type: "custom",
        pitch: -38.702185597346634,
        yaw: 95.89852985911918,
        cssClass: "moveScene",
        scene: "pointTwentyOneView",
      },
      pointNinteen: {
        type: "custom",
        pitch: -6.154894691389755,
        yaw: 119.59007724674676,
        cssClass: "moveScene",
        scene: "pointNinteenView",
      },
      pointTwentyThree: {
        type: "custom",
        pitch: -21.28682509554257,
        yaw: 87.74096921570876,
        cssClass: "moveScene",
        scene: "pointTwentyThreeView",
      },
    },
  },
  pointTwentyThreeView: {
    title: "Balcony [Mogra]",
    image: viewTwentyThree,
    pitch: -13.556237343455367,
    yaw: 106.82400124065606,
    hotSpots: {
      pointTwentyFour: {
        type: "custom",
        pitch: -34.8756917864276,
        yaw: 90.25858176599681,
        cssClass: "moveScene",
        scene: "pointTwentyFourView",
      },
      pointEleven: {
        type: "custom",
        pitch: -3.995383775973049,
        yaw: 98.1657787173505,
        cssClass: "moveScene",
        scene: "pointElevenView",
      },
      pointNinteen: {
        type: "custom",
        pitch: -6.6370193813980665,
        yaw: -135.68893333947798,
        cssClass: "moveScene",
        scene: "pointNinteenView",
      },
      pointTwentyFive: {
        type: "custom",
        pitch: -7.943842291894032,
        yaw: 117.39480794885833,
        cssClass: "moveScene",
        scene: "pointTwentyFiveView",
      },
      pointTwentyTwo: {
        type: "custom",
        pitch:  -30.883292670478685,
        yaw: -93.24496831924304,
        cssClass: "moveScene",
        scene: "pointTwentyTwoView",
      },
    },
  },
  pointTwentyFourView: {
    title: "Balcony [Mogra]",
    image: viewTwentyFour,
    pitch: -17.716993331270565,
    yaw: -17.977480904436963,
    hotSpots: {
      pointEleven: {
        type: "custom",
        pitch: -4.042462682974913,
        yaw: -74.68581218577842,
        cssClass: "moveScene",
        scene: "pointElevenView",
      },
      pointTwentyFive: {
        type: "custom",
        pitch: -4.740398545531977,
        yaw: -29.5710444168091,
        cssClass: "moveScene",
        scene: "pointTwentyFiveView",
      },
      pointNinteen: {
        type: "custom",
        pitch: -3.1892928376305574,
        yaw: 59.048724331450806,
        cssClass: "moveScene",
        scene: "pointNinteenView",
      },
      pointTwentyOne: {
        type: "custom",
        pitch: -31.70443393751002,
        yaw: 82.63166619364303,
        cssClass: "moveScene",
        scene: "pointTwentyOneView",
      },
    },
  },
  pointTwentyFiveView: {
    title: "Living / Dining [Mogra]",
    image: viewTwentyFive,
    pitch: -19.54029653318237,
    yaw: 61.30125171167283,
    hotSpots: {
      pointTwentyThree: {
        type: "custom",
        pitch: -11.516243578191293,
        yaw: -77.8193493013012,
        cssClass: "moveScene",
        scene: "pointTwentyThreeView",
      },
      pointTwentySix: {
        type: "custom",
        pitch: -29.409780280233143,
        yaw: 160.47686779852611,
        cssClass: "moveScene",
        scene: "pointTwentySixView",
      },
      pointFourteen: {
        type: "custom",
        pitch: -25.957039840620816,
        yaw: 69.27163079629203,
        cssClass: "moveScene",
        scene: "pointFourteenView",
      },
      pointThirtyTwo: {
        type: "custom",
        pitch: -25.289971457583245,
        yaw: 127.21305596155226,
        cssClass: "moveScene",
        scene: "pointThirtyTwoView",
      },
    },
  },
  pointTwentySixView: {
    title: "Living / Dining [Mogra]",
    image: viewTwentySix,
    pitch: -25.72363775676657,
    yaw:  109.72830432603473,
    hotSpots: {
      pointTwentyFive: {
        type: "custom",
        pitch: -24.50133627225735,
        yaw: 90.08575952311375,
        cssClass: "moveScene",
        scene: "pointTwentyFiveView",
      },
      pointTwentyThree: {
        type: "custom",
        pitch: -1.4913685618390682,
        yaw: 59.19720641993163,
        cssClass: "moveScene",
        scene: "pointTwentyThreeView",
      },
      pointThirtyTwo: {
        type: "custom",
        pitch: -30.067596924363066,
        yaw: 170.39731342705605,
        cssClass: "moveScene",
        scene: "pointThirtyTwoView",
      },
      pointFourteen: {
        type: "custom",
        pitch: -11.34459296490114,
        yaw: 131.46689716772022,
        cssClass: "moveScene",
        scene: "pointFourteenView",
      },
    },
  },
  pointTwentySevenView: {
    title: "Living / Dining [Mogra]",
    image: viewTwentySeven,
    pitch: -22.057947868042465,
    yaw: 68.52011373930563,
    hotSpots: {
      pointTwentyEight: {
        type: "custom",
        pitch: -19.23680782435917,
        yaw: 35.539586651943075,
        cssClass: "moveScene",
        scene: "pointTwentyEightView",
      },
    },
  },
  pointTwentyEightView: {
    title: "Bathroom / Toilet [Mogra]",
    image: viewTwentyEight,
    pitch: -22.057947868042465,
    yaw: 68.52011373930563,
    hotSpots: {
      pointTwentyNine: {
        type: "custom",
        pitch: -14.756259876965926,
        yaw: 20.5990370748412,
        cssClass: "moveScene",
        scene: "pointTwentyNineView",
      },
      pointTen: {
        type: "custom",
        pitch: -69.34333729248975,
        yaw: -60.838967749884375,
        cssClass: "moveScene",
        scene: "pointTenView",
      },
    },
  },
  pointTwentyNineView: {
    title: "Bathroom / Toilet [Mogra]",
    image: viewTwentyNine,
    pitch: -22.057947868042465,
    yaw: 68.52011373930563,
    hotSpots: {
      pointTwentyEight: {
        type: "custom",
        pitch: -19.23680782435917,
        yaw: 35.539586651943075,
        cssClass: "moveScene",
        scene: "pointTwentyEightView",
      },
      pointTen: {
        type: "custom",
        pitch: -15.769095203668076,
        yaw: -16.605813190421227,
        cssClass: "moveScene",
        scene: "pointTenView",
      },
    },
  },
  pointThirtyView: {
    title: "Bathroom / Toilet [Mogra]",
    image: viewThirty,
    pitch: -22.057947868042465,
    yaw: 68.52011373930563,
    hotSpots: {
      pointThirtyOne: {
        type: "custom",
        pitch: -14.756259876965926,
        yaw: 20.5990370748412,
        cssClass: "moveScene",
        scene: "pointThirtyOneView",
      },
      pointTwenty: {
        type: "custom",
        pitch: -69.34333729248975,
        yaw: -60.838967749884375,
        cssClass: "moveScene",
        scene: "pointTwentyView",
      },
    },
  },
  pointThirtyOneView: {
    title: "Bathroom / Toilet [Mogra]",
    image: viewThirtyOne,
    pitch: -22.057947868042465,
    yaw: 68.52011373930563,
    hotSpots: {
      pointThirty: {
        type: "custom",
        pitch: -19.23680782435917,
        yaw: 35.539586651943075,
        cssClass: "moveScene",
        scene: "pointThirtyView",
      },
      pointTwenty: {
        type: "custom",
        pitch: -15.769095203668076,
        yaw: -16.605813190421227,
        cssClass: "moveScene",
        scene: "pointTwentyView",
      },
    },
  },
  pointThirtyTwoView: {
    title: "Living / Dining [Mogra]",
    image: viewThirtyTwo,
    pitch: -22.057947868042465,
    yaw: 68.52011373930563,
    hotSpots: {
      pointFourteen: {
        type: "custom",
        pitch: -22.976095504054005,
        yaw: 86.15864557346444,
        cssClass: "moveScene",
        scene: "pointFourteenView",
      },
      pointTwentySix: {
        type: "custom",
        pitch: -29.003515977645243,
        yaw: -1.8187552063748804,
        cssClass: "moveScene",
        scene: "pointTwentySixView",
      },
      pointTwentyFive: {
        type: "custom",
        pitch: -25.819935812097345,
        yaw: 47.43056196009605,
        cssClass: "moveScene",
        scene: "pointTwentyFiveView",
      },
      pointTwentyThree: {
        type: "custom",
        pitch: -6.712761506219352,
        yaw: 26.427330822354378,
        cssClass: "moveScene",
        scene: "pointTwentyThreeView",
      },
    },
  },
};

export default Scene;
