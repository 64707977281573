import MograImage1 from "../images/mogra/view1.jpg";
import MograImage2 from "../images/mogra/view4.jpg";
import MograImage3 from "../images/mogra/view7.jpg";
import MograImage4 from "../images/mogra/view5.jpg";
import MograImage5 from "../images/mogra/view22.jpg";
import MograImage6 from "../images/mogra/view28.jpg";
import MograImage7 from "../images/mogra/view26.jpg";
import MograImage8 from "../images/mogra/view24.jpg";
import MograImage9 from "../images/mogra/view6.jpg";
import MograImage10 from "../images/mogra/view3.jpg";
import MograImage11 from "../images/mogra/view15.jpg";
import MograImage12 from "../images/mogra/view30.jpg";
import MograImage13 from "../images/mogra/view29.jpg";
import MograImage14 from "../images/mogra/view18.jpg";

import OrchidImage1 from "../images/orchid/view1.jpg";
import OrchidImage2 from "../images/orchid/view4.jpg";
import OrchidImage3 from "../images/orchid/view6.jpg";
import OrchidImage4 from "../images/orchid/view11.jpg";
import OrchidImage5 from "../images/orchid/view18.jpg";
import OrchidImage6 from "../images/orchid/view16.jpg";
import OrchidImage7 from "../images/orchid/view21.jpg";
import OrchidImage8 from "../images/orchid/view5.jpg";
import OrchidImage9 from "../images/orchid/view20.jpg";
import OrchidImage10 from "../images/orchid/view10.jpg";
import OrchidImage11 from "../images/orchid/view15.jpg";
import OrchidImage12 from "../images/orchid/view16.jpg";



import NeelkamalImage1 from "../images/neelkamal/view1.jpg";
import NeelkamalImage2 from "../images/neelkamal/view2.jpg";
import NeelkamalImage3 from "../images/neelkamal/view4.jpg";
import NeelkamalImage4 from "../images/neelkamal/view6.jpg";
import NeelkamalImage5 from "../images/neelkamal/view8.jpg";
import NeelkamalImage6 from "../images/neelkamal/view10.jpg";
import NeelkamalImage7 from "../images/neelkamal/view11.jpg";
import NeelkamalImage8 from "../images/neelkamal/view13.jpg";
import NeelkamalImage9 from "../images/neelkamal/view9.jpg";
import NeelkamalImage10 from "../images/neelkamal/view5.jpg";



import ParijatImage1 from "../images/parijat/view-2.jpg";
import ParijatImage2 from "../images/parijat/view-3.jpg";
import ParijatImage3 from "../images/parijat/view-5.jpg";
import ParijatImage4 from "../images/parijat/view-7.jpg";
import ParijatImage5 from "../images/parijat/view-9.jpg";
import ParijatImage6 from "../images/parijat/view-10.jpg";
import ParijatImage7 from "../images/parijat/view-11.jpg";
import ParijatImage8 from "../images/parijat/view-4.jpg";


export const propertyData = [
  {
    id: "1",
    name: "Mogra",
    location: "Puri, Odisha, India",
    size: "1580 sqft",
    price:"1,35,00,000",
    rooms: "2 BHK",
    facing: "Sea Facing",
    unit: "14",
    floors: "G+5",
    approval: "ORERA Approved",
    powerbackup: "Yes",
    elevator: "Yes",
    parking: "Yes",
    security: "Yes",
    intercom: "Yes",
    description:
      "The Mogra, a flower held in high esteem by revered deities such as Lord Vishnu and Goddess Laxmi, is an enchanting bloom that serves as the inspiration for the most luxurious dwelling in the Yashila project. The residents of this mesmerizing edifice, situated on the sixth floor, shall enjoy picturesque views of the radiant beach and river from a 24-foot balcony, immersing themselves in the majesty of nature.",
    gallery: [
      { id: "1", imageurl: MograImage1 },
      { id: "2", imageurl: MograImage2 },
      { id: "3", imageurl: MograImage3 },
      { id: "4", imageurl: MograImage4 },
      { id: "5", imageurl: MograImage5 },
      { id: "6", imageurl: MograImage6 },
      { id: "7", imageurl: MograImage7 },
      { id: "8", imageurl: MograImage8 },
      { id: "9", imageurl: MograImage9 },
      { id: "10", imageurl: MograImage10 },
      { id: "11", imageurl: MograImage11 },
      { id: "12", imageurl: MograImage12 },
      { id: "13", imageurl: MograImage13 },
      { id: "14", imageurl: MograImage14 },
    ],
    emipartner: [
      { id: "1", imageurl: "sbi.png", name: "State Bank Of India" },
      { id: "2", imageurl: "baroda.png", name: "Bank Of Baroda" },
      { id: "3", imageurl: "uco.png", name: "UCO Bank" },
      { id: "4", imageurl: "union.png", name: "Union Bank" },
      { id: "5", imageurl: "icici.png", name: "ICICI Bank" },
      { id: "6", imageurl: "lic.png", name: "LIC" },
      { id: "7", imageurl: "hdfc.png", name: "HDFC Bank" },
    ],
    brochure: "brochure.png",
    three60url: "/mogra"
  },
  {
    id: "2",
    name: "Orchid",
    location: "Puri, Odisha, India",
    size: "1450 sqft",
    price:"1,23,00,000",
    rooms: "2 BHK",
    facing: "Sea Facing",
    unit: "14",
    floors: "G+5",
    approval: "ORERA Approved",
    powerbackup: "Yes",
    elevator: "Yes",
    parking: "Yes",
    security: "Yes",
    intercom: "Yes",
    description:
      "Orchids' unique beauty and scarcity have rendered them as a symbol of opulence, status, and prestige. In certain spiritual practices, orchids are associated with personal growth, harmony, and spiritual enlightenment.Regarding the 2 Bhk property with a beach view, it boasts of two balconies and every room faces the beach, providing exceptional lighting and stunning views.",
    gallery: [
      { id: "1", imageurl: OrchidImage1 },
      { id: "2", imageurl: OrchidImage2 },
      { id: "3", imageurl: OrchidImage3 },
      { id: "4", imageurl: OrchidImage4 },
      { id: "5", imageurl: OrchidImage5 },
      { id: "6", imageurl: OrchidImage6 },
      { id: "7", imageurl: OrchidImage7 },
      { id: "8", imageurl: OrchidImage8 },
      { id: "9", imageurl: OrchidImage9 },
      { id: "10", imageurl: OrchidImage10 },
      { id: "11", imageurl: OrchidImage11 },
      { id: "12", imageurl: OrchidImage12 },
    ],
    emipartner: [
      { id: "1", imageurl: "sbi.png", name: "State Bank Of India" },
      { id: "2", imageurl: "baroda.png", name: "Bank Of Baroda" },
      { id: "3", imageurl: "uco.png", name: "UCO Bank" },
      { id: "4", imageurl: "union.png", name: "Union Bank" },
      { id: "5", imageurl: "icici.png", name: "ICICI Bank" },
      { id: "6", imageurl: "lic.png", name: "LIC" },
      { id: "7", imageurl: "hdfc.png", name: "HDFC Bank" },
    ],
    brochure: "brochure.png",
    three60url: "/orchid"
  },
  {
    id: "3",
    name: "Neelkamal",
    location: "Puri, Odisha, India",
    size: "1020 sqft",
    price:"86,00,000",
    rooms: "1 BHK",
    facing: "Sea Facing",
    unit: "14",
    floors: "G+5",
    approval: "ORERA Approved",
    powerbackup: "Yes",
    elevator: "Yes",
    parking: "Yes",
    security: "Yes",
    intercom: "Yes",
    description:
      "The Neelkamal units, a crown jewel of our realm. These enchanted Ibhk units, blessed with the allure of the sea's embrace, offer a vista of endless wonder. And that's not ull, for the Neelkamal flats boast a lounge area adjacent to the balcony, a haven to bask in the majesty of the sea, all from the comfort of your chamber. So, come, indulge in the marvels of our Neelkamal units, and let the sea be your muse.",
    gallery: [
      { id: "1", imageurl: NeelkamalImage1 },
      { id: "2", imageurl: NeelkamalImage2 },
      { id: "3", imageurl: NeelkamalImage3 },
      { id: "4", imageurl: NeelkamalImage4 },
      { id: "5", imageurl: NeelkamalImage5 },
      { id: "6", imageurl: NeelkamalImage6 },
      { id: "7", imageurl: NeelkamalImage7 },
      { id: "8", imageurl: NeelkamalImage8 },
      { id: "9", imageurl: NeelkamalImage9 },
      { id: "10", imageurl: NeelkamalImage10 },
    ],
    emipartner: [
      { id: "1", imageurl: "sbi.png", name: "State Bank Of India" },
      { id: "2", imageurl: "baroda.png", name: "Bank Of Baroda" },
      { id: "3", imageurl: "uco.png", name: "UCO Bank" },
      { id: "4", imageurl: "union.png", name: "Union Bank" },
      { id: "5", imageurl: "icici.png", name: "ICICI Bank" },
      { id: "6", imageurl: "lic.png", name: "LIC" },
      { id: "7", imageurl: "hdfc.png", name: "HDFC Bank" },
    ],
    brochure: "brochure.png",
    three60url: "/neelkamal"
  },
  {
    id: "4",
    name: "Parijat",
    location: "Puri, Odisha, India",
    size: "805 sqft",
    price:"68,50,000",
    rooms: "1 BHK",
    facing: "Garden Facing",
    unit: "14",
    floors: "G+5",
    approval: "ORERA Approved",
    powerbackup: "Yes",
    elevator: "Yes",
    parking: "Yes",
    security: "Yes",
    intercom: "Yes",
    description:
      "As legend whispers, the goddess of prosperity, T Lakshmi, delights in the fragrant petals of the Parijat blooms. This enchanting tree, steeped in magic and fore, has long been used to conjure the favor of the goddess. And now, dear seeker, a cozy I bhk abode beckons you, adorned with a grand balcony that gazes upon the central garden. May this enchanted dwelling bring you wealth and blessings, as the Parijat's bloom pleases the divine goddess.",
    gallery: [
      { id: "1", imageurl: ParijatImage1 },
      { id: "2", imageurl: ParijatImage2 },
      { id: "3", imageurl: ParijatImage3 },
      { id: "4", imageurl: ParijatImage4 },
      { id: "5", imageurl: ParijatImage5 },
      { id: "6", imageurl: ParijatImage6 },
      { id: "7", imageurl: ParijatImage7 },
      { id: "8", imageurl: ParijatImage8 },
    ],
    emipartner: [
      { id: "1", imageurl: "sbi.png", name: "State Bank Of India" },
      { id: "2", imageurl: "baroda.png", name: "Bank Of Baroda" },
      { id: "3", imageurl: "uco.png", name: "UCO Bank" },
      { id: "4", imageurl: "union.png", name: "Union Bank" },
      { id: "5", imageurl: "icici.png", name: "ICICI Bank" },
      { id: "6", imageurl: "lic.png", name: "LIC" },
      { id: "7", imageurl: "hdfc.png", name: "HDFC Bank" },
    ],
    brochure: "brochure.png",
    three60url: "/parijat"
  },
];
