import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Import Components
import Home from '../screen/public/Home';
import AboutUs from '../screen/public/AboutUs';
import ContactUs from '../screen/public/ContactUs';
import PhotoGallery from '../screen/public/PhotoGallery';
import Amentities from '../screen/public/Amentities';
import UserProfile from '../screen/private/UserProfile';
import OurProperties from '../screen/private/OurProperties';
import PropertyDetails from '../screen/private/PropertyDetails';
import ParijatScene from '../components/global/ParijatScene';
import OrchidScene from '../components/global/OrchidScene';
import NeelkamalScene from '../components/global/NeelkamalScene';
import MograScene from '../components/global/MograScene';
import ThreeSixty from '../screen/private/ThreeSixty';

function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/amentities' element={<Amentities />} />
        <Route path='/photo-gallery' element={<PhotoGallery />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/contact-us' element={<ContactUs />} />
        
        <Route path='/parijat' element={<ParijatScene />} />
        <Route path='/orchid' element={<OrchidScene />} />
        <Route path='/neelkamal' element={<NeelkamalScene />} />
        <Route path='/mogra' element={<MograScene />} />

        <Route path='/profile' element={<UserProfile />} />
        <Route path='/properties' element={<OurProperties />} />
        <Route path='/property/:id' element={<PropertyDetails />} />
        <Route path='/three-sixty' element={<ThreeSixty />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Routing;